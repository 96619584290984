import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"

const ThankYou = ({ data }) => (
	<Layout>
		<SEO title="Thank You!" description={data.site.siteMetadata.description} />
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">Thank You!</h6>
					<h3 className="text-white">
						<strong>For reaching out to us.</strong>
					</h3>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content ">
			<div className="container">
				<div className="text-center text-primary">
					<h2 className="mb-0">
						<strong>
							We received your message and will get back to you shortly.
						</strong>
					</h2>
					<div className="mt-5">
						<Link className="readmore-btn section-footer sched-modal" to="/">
							Take me Home
						</Link>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYou

export const thankyouPageQuery = graphql`
	query thankyouPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
